import React from "react";
import { logoApi } from "../../constants/constants";
import { useTheme } from "../../theme/ThemeProvider";

interface LogoProps {
  domain: string;
  size?: number;
}

const Logo: React.FC<LogoProps> = (props) => {
  const { domain, size } = props;
  const { theme } = useTheme();
  return (
    <img
      src={
        theme === "aub"
          ? "/images/aub-logo.svg"
          : `${logoApi.url}${domain}?token=${logoApi.token}&size=${
              size ?? "50"
            }`
      }
      alt={domain}
    />
  );
};

export default Logo;
