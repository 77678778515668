import React, { createContext, useContext, useEffect, useState } from "react";
import { ThemeContextType, ThemeName } from "./types";

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<ThemeName>(() => {
    if (localStorage.getItem("theme") !== "null") {
      return localStorage.getItem("theme") as ThemeName;
    }
    return "digibank";
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    // Remove all theme classes
    const root = document.documentElement;
    root.classList.remove("digibank", "aub");

    // Get bank parameter from URL
    const bankParam = params.get("bank") || theme;

    console.log("Switching theme to:", bankParam);

    // Add the new theme class
    root.classList.add(bankParam);

    // Update theme state and storage
    setTheme(bankParam);
    localStorage.setItem("theme", bankParam);
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within ThemeProvider");
  }
  return context;
}
