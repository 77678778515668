import React, { useEffect, useState } from "react";
import OtpInput from "../OtpInput";
import { banks, oobTransactionStatus } from "../../constants/constants";
import { formatCurrency, otpPinValidation } from "../../utils";
import { updateAuthorizationResultApi } from "../../api/digiBankInstance";
import { dateService, today } from "../../services/dateService";
import { DateFormats } from "../../constants/dateFormats";
import { useToast } from "../toast";
interface ManualAuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  paymentDetails: any;
  openWaitingModal: () => void;
}

const ManualAuthModal: React.FC<ManualAuthModalProps> = ({
  isOpen,
  onClose,
  paymentDetails,
  openWaitingModal,
}) => {
  const [otp, setOtp] = useState("");
  const { addToast } = useToast();

  const handleAuthenticate = () => {
    if (otp.length !== 6) {
      addToast({
        message: "Please enter a valid OTP",
        type: "error",
        duration: 2000,
      });
      return;
    }
    if (otpPinValidation(otp)) {
      updateAuthorizationResultApiRequest(oobTransactionStatus.AUTHORIZED);
    } else {
      updateAuthorizationResultApiRequest(oobTransactionStatus.DECLINED);
    }
  };

  const updateAuthorizationResultApiRequest = async (status: string) => {
    try {
      const response = await updateAuthorizationResultApi(
        {
          transactionAuthorizeStatus: status,
        },
        paymentDetails.transaction.authenticationId
      );

      if (response.status === "SUCCESS") {
        openWaitingModal();
        onClose();
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isOpen) {
      setOtp("");
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const onchangeOtp = (otp: string) => {
    setOtp(otp);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden  xs:w-9/12 md:w-7/12 transform transition-all duration-500 ease-in-out">
        <div className="flex justify-between items-center bg-grey p-4">
          <img
            src={
              paymentDetails?.card.bank === banks.federalBank
                ? "images/federalBankLogo.png"
                : "images/Logo.svg"
            }
            className="w-24"
            alt="brand"
          />
          {paymentDetails?.card.paymentScheme === "MasterCard" && (
            <div className="flex flex-row gap-1 items-center">
              <img
                src="https://www.mastercard.co.in/content/dam/public/mastercardcom/in/en/logos/mc-logo-52.svg"
                alt="Mastercard logo"
                // className="sm:h-8 md:h-10"
              />
              <p className="font-semibold">mastercard</p>
            </div>
          )}
          {paymentDetails?.card.paymentScheme === "Visa" && (
            <div className="flex flex-row gap-1 items-center">
              <img
                src="images/visa.png"
                alt="Mastercard logo"
                className="sm:h-4 md:h-4"
              />
              <p className="font-semibold ">Visa</p>
            </div>
          )}
        </div>
        <div className="p-4 flex flex-col items-center">
          <div className=" flex justify-center">
            {/* Illustration placeholder */}
            <img
              src={`/images/otp-auth-pin-illustration.jpg`}
              alt="Authentication Illustration"
              className=" size-40 object-cover mb-2"
            />
          </div>
          <h2 className="text-3xl font-bold mb-2 text-gray-800 text-center">
            SMS Authentication
          </h2>
          <p className="mb-2 text-gray-600 text-center">
            Enter the OTP sent to your mobile number to authenticate.
          </p>

          <div className="bg-white shadow-md md:w-8/12 xs:w-full rounded-lg p-3 sm:p-4 md:p-5 ">
            <div className="flex items-center">
              <img
                src="images/amazon.png"
                alt={paymentDetails?.merchant.name}
                className="w-8 h-8 sm:w-10 sm:h-10 rounded-full mr-3"
              />
              <div className="flex-grow">
                <p className="font-semibold text-sm sm:text-base md:text-lg">
                  {paymentDetails?.merchant.name}
                </p>
                <p className="text-xs sm:text-sm text-gray-500">
                  {dateService.format(today, DateFormats.basic.standard)}
                </p>
              </div>
              <div>
                <p className="font-bold text-sm sm:text-base md:text-lg">
                  {paymentDetails &&
                    formatCurrency(
                      paymentDetails.transaction.amount,
                      paymentDetails.transaction.currency
                    )}
                </p>
              </div>
            </div>
          </div>

          <OtpInput onChangeOtp={onchangeOtp} invokeChangeOnEnd />
          <div className="flex flex-1">
            <button
              onClick={handleAuthenticate}
              className="w-full bg-yellow-500 text-white py-3 px-6 rounded-full shadow-lg"
            >
              Authenticate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualAuthModal;
