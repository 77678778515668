export const OTP_LENGTH = 6;
export const url = "http://localhost:9000";
export const  DIGIBANK_URL = "https://softtoken-demo.ektar.io/api";

export const oobTransactionStatus = {
  INITIATED: "INITIATED",
  AUTHORIZED: "AUTHORIZED",
  DECLINED: "DECLINED",
  TIMEDOUT: "TIMEDOUT",
};

export const OTP_PIN = "111111";

export const banks = {
  federalBank: "Federal Bank",
  digiBank: "DigiBank",
};

export const logoApi = {
  url: "https://img.logo.dev/",
  token: "pk_NtJ4XZltQN-ezEzdzGBygQ",
};
