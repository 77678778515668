import React, { useEffect, useState } from "react";
// import { CardData } from "../../data/Cards";
import CardItem from "../CardItem";
import { cardItemType } from "../../types/card";
import { useSelector } from "react-redux";
import { selectBasket } from "../../features/basket/basketSlice";
import CartItem from "../CartItem";
import { v4 as uuidv4 } from "uuid";
import { ProductType } from "../../types";
import PaymentApprovalModal from "./PaymentApprovalModal";
import { useNavigate } from "react-router-dom";
import {
  getUserCardsApi,
  initiateOobAuthorizationApi,
} from "../../api/digiBankInstance";
import { constructPayload } from "../../utils";
import ManualAuthModal from "./ManualAuthModal";
import { useToast } from "../toast";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../config/firebase";
import PaymentApprovalModalWithoutPolling from "./PaymentApprovalWithoutPolling";
import { OobAuthorizationApiPayloadType } from "../../api/types";

const PaymentDetails: React.FC = (props) => {
  const [selectedValue, setSelectedValue] = React.useState<cardItemType | null>(
    null
  );

  const [paymentData, setPaymentData] =
    React.useState<OobAuthorizationApiPayloadType | null>(null);
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);
  const [isManualApproveModalOpen, setIsManualApproveModalOpen] =
    useState(false);
  const [
    isWaitingWithoutPollingModalOpen,
    setIsWaitingWithoutPollingModalOpen,
  ] = useState(false);
  const [totlPrice, setTotalPrice] = useState(0);
  const [CardData, setCardData] = useState<any[]>([]);
  const basket = useSelector(selectBasket);
  const navigator = useNavigate();
  const { addToast } = useToast();
  const [user] = useAuthState(auth);
  console.log("user", user);

  const DeliveyCharge = 80.0;
  const handleChange = (item: cardItemType) => {
    console.log("item", item);
    setSelectedValue(item);
  };

  const cardItem = () => {
    return CardData.map((item: cardItemType) => {
      return (
        <CardItem
          selected={item.cardNo === selectedValue?.cardNo}
          selectItem={handleChange}
          item={item}
        />
      );
    });
  };

  const initiateOob = () => {
    if (!selectedValue) {
      addToast({
        message: "please select a payment method",
        type: "error",
        duration: 3000,
      });
    } else {
      initiateOobAuthorizationApiRequest();
    }
  };

  const cartItems = () => {
    return basket?.map(
      ({
        id,
        title,
        image,
        price,
        category,
        description,
        stock,
      }: ProductType) => {
        return (
          <CartItem
            key={uuidv4()}
            id={id}
            title={title}
            image={image}
            price={price}
            category={category}
            description={description}
            stock={stock}
            hideRemoveButton
          />
        );
      }
    );
  };

  useEffect(() => {
    if (basket.length > 0) {
      setTotalPrice(
        basket.map((item) => item.price).reduce((acc, cv) => acc + cv)
      );
    } else {
      navigator("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserCardsApiRequest = async () => {
    try {
      const response = await getUserCardsApi(user?.email || "");
      if (response) {
        console.log("response", response);
        const cards: any = response["oob-cards"];
        if (response["oob-cards"].length === 0) {
          const staticCard = {
            id: "1",
            cardNo: "1234 5678 1234 5678",
            customer: {
              id: "1432535542526g52",
              nickName: user?.email,
              firstName: "Federal Bank",
              lastName: "",
            },
            paymentScheme: "Visa",
            bank: "Federal Bank",
          };
          setCardData([staticCard]);
        } else {
          const staticCard = {
            id: "1",
            cardNo: "1234 5678 1234 5678",
            customer: {
              id: "1432535542526g52",
              nickName: response["oob-cards"][0]
                ? response["oob-cards"][0]?.customer.nickName
                : user?.email,
              firstName: response["oob-cards"][0]
                ? response["oob-cards"][0]?.customer.firstName
                : user?.email,
              lastName: response["oob-cards"][0]
                ? response["oob-cards"][0]?.customer.lastName
                : "",
            },
            paymentScheme: response["oob-cards"][0].paymentScheme ?? "Visa",
            bank: response["oob-cards"][0].bank ?? "Federal Bank",
          };

          let finalCardData: any = [...cards, staticCard];
          setCardData(finalCardData);
        }
      }
    } catch (error: any) {
      console.log("error fetching user cards ==> ", error);
      addToast({
        message: "Failed to fetch user cards",
        type: "error",
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getUserCardsApiRequest();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initiateOobAuthorizationApiRequest = async () => {
    try {
      let payload = constructPayload(
        selectedValue,
        basket,
        setPaymentData,
        DeliveyCharge
      );
      let response = await initiateOobAuthorizationApi(payload);

      if (response.oobAuthType === "TOTP") {
        openWaitingPaymentModal();
      } else {
        setIsManualApproveModalOpen(true);
      }
    } catch (error) {
      setIsManualApproveModalOpen(true);
      console.log(error);
    }
  };

  const openWaitingPaymentModal = () => {
    console.log("selectedValue?.paymentScheme", selectedValue?.paymentScheme);
    if (selectedValue?.paymentScheme === "MasterCard") {
      setIsWaitingModalOpen(true);
    } else {
      setIsWaitingWithoutPollingModalOpen(true);
    }
  };

  return (
    <>
      <div className="flex justify-center m-auto md:p-5 xs:p-2 w-full bg-white">
        <div className="flex justify-center flex-wrap sm:flex-col lg:flex-row">
          <div className="2xl:w-6/12 xs:w-full   ">
            <div className="xs:hidden md:flex border-b border-grey my-5 pb-5">
              <div className="w-1/12 font-bold">1</div>
              <div className="w-5/12 font-bold  text-lg  text-[var(--N4QdCheV6mGo,#0f1111)]">
                Select delivery address
              </div>
              <div className="w-3/12 text-sm  text-[var(--N4QdCheV6mGo,#0f1111)]">
                {" "}
                Prabhu nagar, second street ANDIPALAYAM, TAMIL NADU 641687 Add
                delivery instructions
              </div>
              <div className="w-2/12 text-sm text-right  text-[var(--N4QdCheV6mGo,#0f1111)]">
                Change{" "}
              </div>
            </div>
            <div className="flex border-b border-grey">
              <div className="w-1/12 font-bold xs:hidden md:block">2</div>
              <div className="md:w-11/12 xs:w-full">
                <p className=" text-lg font-bold">Select a payment method</p>
                <div className="w-full rounded-[8px] md:p-3 md:border border-1 border-grey my-3">
                  <h1 className="font-bold">CREDIT & DEBIT CARDS</h1>

                  <div className="w-full flex justify-end border-b border-grey m-0  px-4">
                    <div className="w-2/12">
                      <p className="text-sm">Nick name</p>
                    </div>
                  </div>
                  {cardItem()}
                </div>
              </div>
            </div>
            <div className="flex border-b border-grey  my-5 pb-5 ">
              <div className="w-1/12 text-[12px]text-base font-bold xs:hidden md:block">
                3
              </div>
              <div>
                <p className=" text-lg font-bold">Review items and delivery</p>
                {cartItems()}
              </div>
            </div>
          </div>
          <div className="2xl:w-3/12 xl:12/12 md:12/12 xs:w-full md:ml-4">
            <div className="md:w-10/12 xs:w-full md:border border-grey rounded">
              <div className="w-full p-4 flex  flex-col justify-center">
                <button
                  onClick={initiateOob}
                  className="bg-yellow-300 p-2  px-4 rounded-full text-sm font-semibold"
                  type="submit"
                  name="submit"
                  id="submit-form"
                >
                  Place your order and pay
                </button>

                <p className="text-sm my-2 text-gray-500 font-semibold">
                  Choose a payment method to continue checking out. You'll still
                  have a chance to review your order before it's final.
                </p>

                <div className="border-y border-grey pt-4 pb-2">
                  <p className="text-lg font-bold ">Order Summary</p>

                  <div className="flex justify-between text-sm">
                    <p>Items:</p>
                    <p>AED {basket.length > 0 && totlPrice}</p>
                  </div>
                  <div className="flex justify-between text-sm">
                    <p>Delivery:</p>
                    <p>AED {DeliveyCharge}.00</p>
                  </div>
                </div>
                <div className="border-b border-grey py-2 flex justify-between">
                  <p className="text-red-950 font-bold">Total</p>
                  <p className="text-red-950 font-bold">
                    AED {basket.length > 0 && totlPrice + DeliveyCharge}
                  </p>
                </div>
              </div>
              <div className="w-full bg-grey p-1 px-4">
                <p className="text-xs font-bold">Important:</p>
                <p className="text-xs ">
                  you can contact out customer care for any queries about the
                  delivery.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManualAuthModal
        isOpen={isManualApproveModalOpen}
        paymentDetails={paymentData}
        onClose={() => setIsManualApproveModalOpen(false)}
        openWaitingModal={() => setIsWaitingWithoutPollingModalOpen(true)}
      />
      <PaymentApprovalModal
        isOpen={isWaitingModalOpen}
        paymentDetails={paymentData}
        timeoutDuration={300}
        onApprove={() => {
          setIsWaitingModalOpen(false);
        }}
        onClose={() => {
          setIsWaitingModalOpen(false);
        }}
      />

      <PaymentApprovalModalWithoutPolling
        isOpen={isWaitingWithoutPollingModalOpen}
        paymentDetails={paymentData}
        timeoutDuration={300}
        onApprove={() => {
          setIsWaitingWithoutPollingModalOpen(false);
        }}
        onClose={() => {
          setIsWaitingWithoutPollingModalOpen(false);
        }}
      />
    </>
  );
};

export default PaymentDetails;
